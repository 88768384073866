import Offcanvas from "react-bootstrap/Offcanvas";
import yafilogo from "../img/yafilogo.avif";
import Logo from "../img/Logo.png";
import { useContext } from "react";
import { TbLogout } from "react-icons/tb";
import { useLocation, useNavigate } from "react-router-dom";
import { Context } from "../context/Context";

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currPath = location.pathname;
  const { show, setShow } = useContext(Context);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleSignInClick = () => {
    
    navigate("/SignIn");
  };
  return (
    <>
      <div className="cx-sidebar-main " style={{ zIndex: 1 }}>
        <div className="sidebar d-flex  flex-column justify-content-between sidecontent">
          <div>
            <div className="  p-4 logomaindiv ">
              <a href="d-flex align-item-center ">
                <i className="fs-5    ">
                  <img src={Logo} alt="logo" className="blacklogoimage" style={{width:150, height:100}}/>
                </i>
              </a>
            </div>
            <div className="menu-wrapper">
              <div className="menus">
                <div
                  className={
                    currPath === "/DashBoard" ? "menu active-menu" : "menu"
                  }
                  onClick={() => navigate("/DashBoard")}
                >
                  Dashboard
                </div>

                <div
                  className={
                    currPath === "/ProductManagement"
                      ? "menu active-menu"
                      : "menu"
                  }
                  onClick={() => navigate("/ProductManagement")}
                >
                  Product Management
                </div>

                <div
                  className={
                    currPath === "/AddProduct" ? "menu active-menu" : "menu"
                  }
                  onClick={() => navigate("/AddProduct")}
                >
                  Add Product
                </div>

                <div
                  className="logout menu"
                  style={{ color: "#1154DD" }}
                  onClick={() => navigate("/")}
                >
                  <TbLogout className="TbLogout" color="#101376" style={{}} /> LOG OUT
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mobile-sidebar">
        <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Body>
            <div className="sidebar d-flex  flex-column justify-content-between sidecontent">
              <div>
                <div className="  p-4 logomaindiv ">
                  <a href="d-flex align-item-center ">
                    <i className="fs-5    ">
                      <img
                        src={Logo}
                        alt="logo"
                        className="blacklogoimage"
                      />
                    </i>
                  </a>
                </div>
                <div className="menu-wrapper">
                  <div className="menus">
                    <div
                      className={
                        currPath === "/DashBoard" ? "menu active-menu" : "menu"
                      }
                      onClick={() => {navigate("/DashBoard");
                        ;
                        handleClose()
                      }}
                    >
                      Dashboard
                    </div>

                    <div
                      className={
                           currPath === "/ProductManagement" || currPath === "/editproduct"
                          ? "menu active-menu"
                          : "menu"
                      }
                      onClick={() => {navigate("/ProductManagement");
                        handleClose()
                      }}
                    >
                      Product Management
                    </div>

                    <div
                      className={
                        currPath === "/AddProduct"
                          ? "menu active-menu"
                          : "menu"
                      }
                      onClick={() =>{navigate("/AddProduct");
                        handleClose()
                      }}
                    >
                      Add Product
                    </div>

               

                

                    <div
                      className="logout menu"
                      style={{ color: "#7422e7" }}
                      onClick={() => navigate("/")}
                    >
                      <TbLogout className="TbLogout" color="#7422e7" /> LOG OUT
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
};

export default Sidebar;
