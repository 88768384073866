import React, { useState } from "react";
import Layout from "../Component/Layout";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

function Addproduct() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 415);
  const [productDetails, setProductDetails] = useState(null);
  const [mainImage, setMainImage] = useState(null);
  const [additionalImages, setAdditionalImages] = useState([]);
  const [mainImageColor, setMainImageColor] = useState("");
  const [additionalImageColors, setAdditionalImageColors] = useState([]);
  const [newImageColors, setNewImageColors] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [colorId, setColorId] = useState("");
  const [imageIds, setImageIds] = useState("");
  const [selectedAdditionalImg, setSelectedAdditionalImg] = useState(null);
  const [newImg, setNewImg] = useState("");
  const [colorName, setColorName] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProductDetails({ ...productDetails, [name]: value });
  };

  console.log(productDetails, "productdetails");
  const savedEmail = localStorage.getItem("email");
  console.log(savedEmail, "savedEmail");

  //..........AddProductApi..........//

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("product_name", productDetails.product_name);
    formData.append("product_description", productDetails.product_description);
    formData.append("product_price", productDetails.product_price);
    formData.append("product_stock", productDetails.product_stock);
    formData.append("user_email", savedEmail);

    // Append image file if selected
    if (mainImage) {
      formData.append("image", mainImage);
    }

    try {
      const response = await fetch(
        "https://lunarsenterprises.com:5007/petshop/add-product",
        {
          method: "POST",
          body: formData,
        }
      );
      const data = await response.json();
      if (data.result === true) {
        toast.success("Product added successfully", {
         
          autoClose: 3000,
        });

        // Clear form after successful submission
        setProductDetails({
          product_name: "",
          product_description: "",
          product_price: "",
          product_stock: "",
        });
        setMainImage(null); // Reset main image state

        // Navigate to Dashboard after 3 seconds
        setTimeout(() => {
          navigate("/Dashboard");
        }, 3000);
      } else {
        toast.error("Error adding product: " + data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.error("Error adding product", {
        position: toast.POSITION.TOP_CENTER,
      });
      console.error("Error adding product:", error);
    }
  };
  
 

  const handleMainImageChange = (e) => {
    setMainImage(e.target.files[0]);
  };
  console.log(mainImage, "hlooo");
  const handleAdditionalImagesChange = (e) => {
    setAdditionalImages(Array.from(e.target.files));
  };

  return (
    <Layout>
      <form onSubmit={handleSubmit}>
        <div className="product-display">
          <div className="selected-product">
            <div className="product-details">
              <h5 style={{ color: "black", fontSize: 25, fontWeight: 500 }}>
                Add Product
              </h5>
              <div className="selected-product-price">
                <h6 style={{ color: "black", fontSize: 16 }}>Price</h6>
                <input
                  className="addinput form-control"
                  name="product_price"
                  value={productDetails?.product_price || ""}
                  onChange={handleInputChange}
                  placeholder="original price"
                />
              </div>

              <h6 style={{ color: "black", fontSize: 16 }}>Product Stock</h6>
              <div>
                <input
                  className="addinput form-control"
                  name="product_stock"
                  value={productDetails?.product_stock || ""}
                  onChange={handleInputChange}
                  placeholder="product quantity"
                />
              </div>

              <div className="selected-product-details">
                <h6 style={{ color: "black", fontSize: 16 }}>Product Name</h6>
                <input
                  className="addinput form-control"
                  name="product_name"
                  value={productDetails?.product_name || ""}
                  onChange={handleInputChange}
                  placeholder="product name"
                />

                <h6 style={{ color: "black", fontSize: 16 }}>Product Image</h6>
                <input type="file" onChange={handleMainImageChange} />

                {/* <h6 style={{ color: "black", fontSize: 16 }}>Quantity</h6> */}
                {/* <input
                  className="addinput form-control"
                  name="quantity"
                  value={productDetails?.quantity || ""}
                  onChange={handleInputChange}
                  placeholder="quantity"
                /> */}

                <h6 style={{ color: "black", fontSize: 16 }}>Description</h6>
                <input
                  className="addinputdes form-control"
                  name="product_description"
                  value={productDetails?.product_description || ""}
                  onChange={handleInputChange}
                  placeholder="description"
                />
              </div>
              <div className="add-buy-buttons">
                <button
                  type="submit"
                  style={{
                    backgroundColor: "#7AB730",
                    color: "white",
                    borderRadius: 10,
                    fontSize: 16,
                    fontWeight: 500,
                    border: "none",
                  }}
                >
                  ADD PRODUCT
                </button>
               
              </div>
            </div>
          </div>
        </div>
      </form>
      {showMessage && (
        <div
          className="success-message"
          style={{
            zIndex: "9999999",
            backgroundColor: "green",
            textAlign: "center",
            position: "fixed",
            top: "10px",
            left: "0",
            width: "100%",
            height: "50px",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "20px",
          }}
        >
          Product added successfully
        </div>
      )}
      <ToastContainer/>
    </Layout>
  );
}

export default Addproduct;
