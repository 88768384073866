import React, { useEffect, useRef, useState } from "react";

import Layout from "../Component/Layout";
import axios from "axios";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register the components with Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function Dashboard() {
  const [timeFrame, setTimeFrame] = useState("week");
  const [summary, setSummary] = useState([]);

  const chartRef = useRef(null);
  const [chartData, setChartData] = useState([]);

  //............BarGraph Api...........//
  useEffect(() => {
    BarGraphApi();
  }, []);

  const BarGraphApi = async () => {
    try {
      // console.log(timeFrame);
      const response = await axios.post(
        "https://lunarsenterprises.com:5007/petshop/stock",
        { email: savedEmail }
      );
      setChartData(response.data.stock || []);
      console.log(response.data.stock, "stockchart");
    } catch (error) {
      console.log(error);
    }
  };
  const productNames = chartData?.map((product) => product.p_name);
  const productStock = chartData?.map((product) => product.p_stock);

  const data = {
    labels: productNames,
    datasets: [
      {
        label: "Stock",
        data: productStock,
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  // Options for the bar chart
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const product = chartData[context.dataIndex];
            return `${product.p_name}: ${product.p_stock}`;
          },
        },
      },
      title: {
        display: true,
        text: "Product Stock Levels",
      },
    },
  };

  // const orderList = [
  //   {
  //     order_id: "1",
  //     product_name: "abcd",
  //     user_address: "vra 31, abcdd, tvm",
  //     phoneno: "999788767",
  //   },
  // ];

  //......................OrderList..............//
  const [list, setList] = useState([]);

  const savedEmail = localStorage.getItem("email");
  console.log(savedEmail, "savedEmail");

  useEffect(() => {
    OrderList();
  }, []);

  const OrderList = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:5007/petshop/order-list",
        {
          email: savedEmail,
        }
      );
      setList(response.data.orderlist);
      console.log(response.data.orderlist, "orderlistdata");
    } catch (error) {
      console.log(error);
    }
  };
  const handleTimeFrameChange = (newTimeFrame) => {
    setTimeFrame(newTimeFrame);
    // revenueShow();
  };

  return (
    <Layout>
      <div className="container">
        <div className="col-12">
          <div className="row">
            {/* <div className="col-md-6 bgwhites">
              <div>
                <h1 className="text-center">Product Summary</h1>
                <div className="align-items-center justify-content-center d-flex">
                  <canvas ref={chartRef} width="200" height="250"></canvas>
                </div>
              </div>

              <div className="col-md-12 align-items-center justify-content-center d-flex">
                {summary &&
                  summary.map((item, index) => (
                <div
                key={index}
                >
                  <div className="graphTextside">
                    <div className="graphTextsideDotapplied"></div>
                    <h6>confirm :{item.confirmed}</h6>
                  </div>
                  <div className="graphTextside">
                    <div className="graphTextsideDotavailed"></div>
                    <h6>Pending :{item.pending} </h6>
                  </div>
                  <div className="graphTextside">
                    <div className="graphTextsideDotReject"></div>
                    <h6>cancelled : {item.cancelled}</h6>
                  </div>
                </div>
                ))} 
              </div>
            </div> */}

            <div className="col-md-12 bgwhites">
              <div className="chart-container" >
                <h1 className="text-center">Stock Availability </h1>

                <Bar data={data} options={options} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-5">
        <div className="table-responsive d-flex pe-3 ps-3 pb-0 bgWhite">
          <table
            className="table table-hover custom-table table-borderless"
            style={{ minWidth: "700px" }}
          >
            <thead className="bordertablesho">
              <tr className="line2px-green">
                <th scope="col">Sr No</th>
                <th scope="col">Product Id</th>
                <th scope="col">Product Name</th>
                <th scope="col">User Name</th>
                <th scope="col">User Address</th>
                <th scope="col">User Phone.No</th>
                {/* <th scope="col">Payment Status</th> */}
              </tr>
            </thead>
            <tbody>
              {list?.map((order, index) => (
                <tr className="line1px" key={order.o_id}>
                  <th scope="row">{order.o_id + 1}</th>
                  <td>{order.o_product_id}</td>
                  <td>{order.p_name}</td>
                  <td>{order.o_user_name}</td>
                  <td>{order.o_user_address}</td>
                  <td>{order.o_user_phone_number}</td>
                  {/* <td>{order.order_status}</td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
}

export default Dashboard;
