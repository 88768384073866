import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import Layout from "../Component/Layout";
import Petshampoo from "../img/petshampoo.png";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";

function EditProduct() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 415);
  // const [productDetails, setProductDetails] = useState(null);
  const [mainImage, setMainImage] = useState(null);
  const [additionalImages, setAdditionalImages] = useState([]);
  const [mainImageColor, setMainImageColor] = useState("");
  const [additionalImageColors, setAdditionalImageColors] = useState([]);
  const [newImageColors, setNewImageColors] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [colorId, setColorId] = useState("");
  const [imageIds, setImageIds] = useState("");
  const [selectedAdditionalImg, setSelectedAdditionalImg] = useState(null);
  const [newImg, setNewImg] = useState("");
  const [colorName, setColorName] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  console.log(mainImage, "hlooo");

  const location = useLocation();
  const { product } = location.state;
  const navigate = useNavigate();
  console.log(product, "prodetail");

  const [productDetails, setProductDetails] = useState({
    product_name: "",
    product_price: "",
    product_stock: "",
    product_description: "",
    product_image: null, // If you want to handle images separately
  });
  useEffect(() => {
    if (product) {
      setProductDetails({
        product_name: product.p_name || "",
        product_price: product.price || "",
        product_stock: product.p_stock || "",
        product_description: product.p_description || "",
        image: `https://lunarsenterprises.com:5007/${product.p_image}` || null, // Assuming product_image state matches your form field
      });
    }
  }, [product]);

  // Handle changes to form fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProductDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleMainImageChange = (e) => {
    setProductDetails((prevDetails) => ({
      ...prevDetails,
      product_image: e.target.files[0],
    }));
  };
  const savedEmail = localStorage.getItem("email");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append("product_id", product.p_id);
      formData.append("product_name", productDetails.product_name);
      formData.append(
        "product_description",
        productDetails.product_description
      );
      formData.append("product_price", productDetails.product_price);
      formData.append("product_stock", productDetails.product_stock);
      formData.append("user_email", savedEmail);

      // Check if a new image file is selected
      if (productDetails.product_image instanceof File) {
        formData.append("image", productDetails.product_image);
      }

      const response = await axios.post(
        "https://lunarsenterprises.com:5007/petshop/edit-product",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Update successful:", response.data);
      toast.success(response.data.message);
      // setShowMessage(true);

      setTimeout(() => {
        // setShowMessage(false);
        navigate("/ProductManagement");
      }, 3000);
    } catch (error) {
      toast.error("Error updating product");
      console.error("Error updating product:", error);
      // Handle error, show error message, etc.
    }
  };

  return (
    <Layout>
      <form onSubmit={handleSubmit}>
        <div className="product-display">
          <div className="selected-product">
            <div className="product-details">
              <h5 style={{ color: "black", fontSize: 25, fontWeight: 500 }}>
                Edit Product
              </h5>
              <div className="selected-product-price">
                <h6 style={{ color: "black", fontSize: 16 }}>Price</h6>
                <input
                  className="addinput form-control"
                  name="product_price"
                  placeholder="original price"
                  value={productDetails.product_price}
                  onChange={handleInputChange}
                />
              </div>

              <h6 style={{ color: "black", fontSize: 16 }}>Product Stock</h6>
              <input
                className="addinput form-control"
                name="product_stock"
                placeholder="product quantity"
                value={productDetails.product_stock}
                onChange={handleInputChange}
              />

              <div className="selected-product-details">
                <h6 style={{ color: "black", fontSize: 16 }}>Product Name</h6>
                <input
                  className="addinput form-control"
                  name="product_name"
                  placeholder="product name"
                  value={productDetails.product_name}
                  onChange={handleInputChange}
                />

                <h6 style={{ color: "black", fontSize: 16 }}>Product Image</h6>
                {mainImage && !productDetails.product_image && (
                  <img
                    src={mainImage}
                    alt="Main Product"
                    style={{ maxWidth: "200px", marginBottom: "10px" }}
                  />
                )}
                <input
                  type="file"
                  onChange={handleMainImageChange}
                  accept="image/*"
                />

                <h6 style={{ color: "black", fontSize: 16 }}>Description</h6>
                <input
                  className="addinputdes form-control"
                  name="product_description"
                  placeholder="description"
                  value={productDetails.product_description}
                  onChange={handleInputChange}
                />
              </div>
              <div className="add-buy-buttons">
                <button
                  type="submit"
                  style={{
                    backgroundColor: "#7AB730",
                    color: "white",
                    borderRadius: 10,
                    fontSize: 16,
                    fontWeight: 500,
                    border: "none",
                  }}
                >
                  UPDATE NOW
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      {showMessage && (
        <div
          className="success-message"
          style={{
            zIndex: "9999999",
            backgroundColor: "green",
            textAlign: "center",
            position: "fixed",
            top: "10px",
            left: "0",
            width: "100%",
            height: "50px",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "20px",
          }}
        >
          Product Updated successfully
        </div>
      )}
      <ToastContainer />
    </Layout>
  );
}

export default EditProduct;
