import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FaEyeSlash } from "react-icons/fa";
import { FaEye } from "react-icons/fa6";
import OTPInput from "react-otp-input";
import lock from "../img/lock.png";
import user from "../img/user.png";
import Logo from "../img/Logo.png";
import { useNavigate } from "react-router-dom";

function SignIn() {
  const handleOtpClose = () => setShowOtp(false);
  const handleOtpOpen = () => setShowOtp(true);
  const [showOtp, setShowOtp] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const navigate = useNavigate();


  const handleSignIn = () => {
    if (email.trim() === "" || password.trim() === "") {
      setShowMessage("Please enter valid credentials.");
    } else {
      navigate("/dashboard");
      console.log("Signed in successfully!");
    }
  };


  //.......Login_Api......//

  useEffect(() => {
    const savedEmail = localStorage.getItem("email");
    const savedPassword = localStorage.getItem("password");
    if (savedEmail && savedPassword) {
      setEmail(savedEmail);
      setPassword(savedPassword);
    }
  }, []);

  const handleSignInClick = async () => {
    try {
      const response = await fetch(
        "https://lunarsenterprises.com:5007/petshop/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
            password: password,
          }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        console.log("Login successful:", data.user_id);
        const user_id = data.user_id;
        if (data.result === true) {
          localStorage.setItem("email", email);
          localStorage.setItem("password", password);
          navigate("/Dashboard", { state: { user_id } });
        } else {
          setShowMessage(true);
          document
            .querySelectorAll("input")
            .forEach((input) => (input.style.border = "1px solid red"));
          setTimeout(() => setShowMessage(false), 3000);
          navigate("/SignIn");
        }
      } else {
        console.error("Login failed:", data.message);
        alert("Login failed: " + data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <div className="login-main container-fluid">
      <div className="signinstyle">
        <img
          src={Logo}
          className="redlogo"
          alt="Red Logo"
          height="75px"
          width="150px"
        />
        <p
          className="mb-4"
          style={{
            color: "#7AB730",
            fontWeight: "bold",
            fontSize: "40px",
            textAlign: "center",
          }}
        >
          Sign In
        </p>

        <div className="login-wrapper-inner">
          <div className="mt-4 input-wrapper-login">
            <div className="icondirection icon-input-login">
              <img src={user} className="Reguser-icon" alt="User Icon" />
            </div>
            <input
              type="text"
              className="form-control login-input  "
              placeholder="Enter User Name"
              aria-label="Enter User Name"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mt-4 input-wrapper-login">
            <div className="icondirection icon-input-login">
              <img src={lock} className="Reguser-icon" alt="Lock Icon" />
            </div>
            <input
              type={showPassword ? "text" : "password"}
              className="form-control login-input"
              placeholder="Enter Password"
              aria-label="Enter Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div
              className="password-toggle-icon"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <FaEyeSlash style={{width:"24px", height:"24px"}} /> : <FaEye  style={{width:"24px", height:"24px"}}/>}
            </div>
          </div>
          <div className="text-center mt-3">
            <a
              href="#"
              className="text-decoration-none text-black colorforgot"
              onClick={handleShow}
            >
              Forgot Your Password?
            </a>
            <div className="linestyless d-flex justify-content-center align-items-center mt-2"></div>
          </div>

          <div className="d-flex justify-content-center align-items-center mt-4">
            <button className="btnsign" onClick={handleSignInClick}>
              SIGN IN
            </button>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="modal-dialog-centered"
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Forgot Password?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mt-4 input-wrapper-login">
            <label style={{ marginLeft: 0, marginBottom: 10 }}>Email</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Your Email"
              aria-label="Enter User Name"
            />
            <label style={{ marginLeft: 0, marginTop: 10 }}>
              OTP sent to email
            </label>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              handleClose();
              handleOtpOpen();
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showOtp}
        onHide={handleOtpClose}
        dialogClassName="modal-dialog-centered"
        aria-labelledby="otp-modal-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="otp-modal-title">OTP Verification</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex align-items-center justify-content-center mt-2">
          <OTPInput
            // value={otp}
            // onChange={setOtp}
            numInputs={6}
            renderSeparator={<span>-</span>}
            inputStyle={{
              width: "3rem",
              height: "3rem",
              fontSize: "2rem",
              borderRadius: 4,
              border: "3px solid red",
            }}
          />

          <div>
            <div className="text-center mt-3">
              {/* {timer > 0 ? (
              <p>Resend OTP in {timer} seconds</p>
            ) : (
              <Button variant="link" onClick={handleResend}>
                Resend OTP
              </Button>
            )} */}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleOtpClose}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      {showMessage && (
        <div
          className="success-message"
          style={{
            zIndex: "9999999",
            backgroundColor: "red",
            textAlign: "center",
            position: "fixed",
            top: "10px",
            left: "0",
            width: "100%",
            height: "50px",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "20px",
          }}
        >
          Enter valid credentials
        </div>
      )}
    </div>
  );
}

export default SignIn;
