import React, { useContext } from "react";
import { CiSearch } from "react-icons/ci";
import { FaPlus } from "react-icons/fa";
import { HiBars3 } from "react-icons/hi2";
import { useLocation } from "react-router-dom";
import { Context } from "../context/Context";

const Header = ({
  showNewAdminButton,
  showAdminButton2,
  showAdminButton3,
  showAdminButtonStudent,
  showAdminButtonPrivelege,
  showAdminButtonGuest,
}) => {
  const location = useLocation();
  const currpath = location.pathname;

  const { show, setShow } = useContext(Context);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="cx-header-main">
      <div className="wrapper">
        <div className="h-left">
          <div className="mobile-menu" onClick={() => setShow(!show)}>
            <HiBars3 />
          </div>
          <h4 className="heading-main">
            {(currpath === "/DashBoard" && "Dashboard") ||
              (currpath === "/Product/Management" && "Product Management") ||
              (currpath === "/Add/Product" && "Add Product") ||
              (currpath === "/Edit/Product" && "Edit Product") 
             }
          </h4>
        </div>
        <div className=" mainscreensearch">
       
          
          {showAdminButton3 && (
            <div className="col-md-6 text-end">
              <button className="btn-style">
                <FaPlus className="text-white me-2" /> Subcategory
              </button>
            </div>
          )}

          {showAdminButtonStudent && (
            <div className="col-md-6 text-end">
              <button className="btn-style">
                <FaPlus className="text-white me-2" />
                Create Student
              </button>
            </div>
          )}
          {showAdminButtonPrivelege && (
            <div className="col-md-6 text-end">
              <button className="btn-style">
                <FaPlus className="text-white me-2" /> Create Priveleged User
              </button>
            </div>
          )}
          {showAdminButtonGuest && (
            <div className="col-md-6 text-end">
              <button className="btn-style">
                <FaPlus className="text-white me-2" />
                Create Guest Admin
              </button>
            </div>
          )}
         
        </div>
      </div>
    </div>
  );
};

export default Header;
